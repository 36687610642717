import { useState } from "react";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";

const AlertMessage = (props) => {
  return (
    <Alert
      variant={props.variant}
      style={{ fontWeight: "bold", fontSize: "24px" }}
    >
      {props.text}
    </Alert>
  );
};

export default function FormWA(props) {
  const [phoneNumber, setphoneNumber] = useState("");
  const handlePhoneNumberInputChange = (event) => {
    setphoneNumber(event.target.value);
    setAlertDangerDisplay(false);
    setAlertSuccessDisplay(false);
  };

  const resetPhoneNumberInputChange = (event) => {
    setphoneNumber("");
    setAlertDangerDisplay(false);
    setAlertSuccessDisplay(false);
  };

  // Error message box
  const [alertDangerDispaly, setAlertDangerDisplay] = useState(false);
  const [alertDangerText, setAlertDangerText] = useState("");

  // Success message box
  const [alertSuccessDisplay, setAlertSuccessDisplay] = useState(false);
  const [alertSuccessText, setAlertSuccessText] = useState("");

  // Daniel-Spiegel
  const handleOpenChat = () => {
    let phone_number = phoneNumber;
    // empty number
    if (!phone_number) {
      console.log(phone_number);
      setAlertDangerText("נא לרשום מספר טלפון");
      setAlertDangerDisplay(true);
      return;
    }

    // remove unused characters
    phone_number = phone_number.trim();
    phone_number = phone_number.replace(/\s|-/g, "");
    console.log("remove unused characters: " + phone_number);

    // verify length
    if (phone_number.length !== parseInt(10)) {
      console.log(phone_number);

      setAlertDangerText("מספר הטלפון חייב להיות בן 10 ספרות");
      setAlertDangerDisplay(true);
      return;
    }

    // verify begins with zero
    if (phone_number.charAt(0) !== "0") {
      console.log(phone_number);

      setAlertDangerText("המספר חייב להתחיל בספרה אפס");
      setAlertDangerDisplay(true);
      return;
    }

    // valid number
    console.log("valid: " + phone_number);

    const url = "https://api.whatsapp.com/send?l=he&phone=972" + phone_number;
    const win = window.open(url, "_blank");
    win.focus();

    // clear alerts messages
    setAlertDangerDisplay(false);
    setAlertSuccessText("מעביר לוואטסאפ בחלון חדש...");
    setAlertSuccessDisplay(true);
  };

  return (
    <div>
      <Form>
        <Row>
          <Col>
            <Form.Control
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberInputChange}
              size="lg"
              placeholder="רישמו כאן את מספר הטלפון"
              style={{ direction: "ltr" }}
            />
            <div style={{ direction: "rtl", textAlign: "right" }}>
              <Form.Text className="text-muted">
                כרגע עובד למספרים ישראליים בלבד, אם מעונינים בעוד מדינות, תעדכנו
                אותי ואוסיף
              </Form.Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="success"
              size="lg"
              onClick={handleOpenChat}
              style={{ marginTop: "5px" }}
            >
              {"המשך >>"}
            </Button>
          </Col>
          <Col>
            <Button
              variant="secondary"
              size="lg"
              onClick={resetPhoneNumberInputChange}
              style={{ marginTop: "5px" }}
            >
              ניקוי
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {alertDangerDispaly ? (
              <AlertMessage
                variant="danger"
                text={alertDangerText}
              ></AlertMessage>
            ) : null}
            {alertSuccessDisplay ? (
              <AlertMessage
                variant="success"
                text={alertSuccessText}
              ></AlertMessage>
            ) : null}
          </Col>
        </Row>
      </Form>
    </div>
  );
}
