import "./App.css";
import { Container, Row } from "react-bootstrap";

import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Container>
        <Row>
          <Header />
        </Row>
        <Row>
          <Content />
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  );
}

export default App;
